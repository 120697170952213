<template>
  <div>
    <CRow>
      <CCol sm="12">
        <p>
          Carga aqui el listado de usuarios para registrar.
        </p>
        <form @submit.prevent="submit">
          <input type="file" @change="handleFileUpload( $event )">
          <br><br>
          <CButton
            @click="send()"
            color="primary"
          >
            <CSpinner v-if="loading" variant="grow" size="sm" />
            Enviar
          </CButton>
        </form>
        <a :href="urlBase+'/invoices/userformat.xlsx'">
          Descargar formato
        </a>
        <CCard>
          <CCardBody>
            <h3>Roles disponibles</h3>
            <ul>
              <li v-for="(item, index) in role" :key="index">{{ item }}</li>
            </ul>
          </CCardBody>
        </CCard>
      </CCol>
      
    <CToaster :autohide="3000">
      <template v-for="(item, index) in toasts">
        <CToast
          :key="`${item.title}-${index}`"
          :show="true"
          :color="item.color"
          :header="item.title"
        >
          {{ item.message }}
        </CToast>
      </template>
    </CToaster>
    </CRow>
  </div>
</template>

<script>

export default {
  name: 'ImportUsers',
  data(){
    return {
      file: '',
      toasts: [],
      role: [
        'Administrador',
        'Vendedor BSmart',
        'Mayorista',
        'Mayorista Vendedor',
        'Distribuidor',
        'Subdistribuidor',
        'Financiero',
        'Soporte',
      ],
      loading: false,
      urlBase: process.env.VUE_APP_BACKEND_URL,
    }
  },
  mounted() {
    if(this.$store.state.user.role == 'Mayorista'){
      this.role = [
        'Mayorista Vendedor',
        'Distribuidor',
      ];
      this.form.role = 'Distribuidor';
    }
    if(this.$store.state.user.role == 'Mayorista Vendedor'){
      this.role = [
        'Distribuidor',
      ];
      this.form.role = 'Distribuidor';
    }
    if(this.$store.state.user.role == 'Vendedor BSmart'){
      this.role = [
        'Mayorista',
      ];
      this.form.role = 'Mayorista';
    }
  },
  methods: {
    handleFileUpload( event ){
      this.file = event.target.files[0];
    },

    async send() {
      this.loading = true;
      let formData = new FormData();
      formData.append('file', this.file);
      await axios.post( process.env.VUE_APP_BACKEND_URL+'/api/users/import',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Accept':'application/json',
              'Authorization': `Bearer `+ this.$store.state.user.token
          }
        }
      ).then((data) => {
        this.loading = false;
        this.toasts.push({
            title: "Confirmación",
            color: "success",
            message: "Usuarios registrados con exito",
          });
      })
      .catch((error) => {
        this.loading = false;
        var errors = error.response.data.errors;
        var error = {};
        Object.keys(errors).forEach((key) => {
          error = {
            title: "Error al registrar usuarios",
            color: "danger",
            message: errors[key],
          };
        });
        this.toasts.push(error);
      });
    }
  }
}
</script>
